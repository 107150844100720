import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { MC } from '../../../services/utils/types';
import withLoginHandler from '../../hocs/with-login-handler/with-login-handler';
import LoadingBackdrop from '../../loading-backdrop/loading-backdrop';
import { useStore } from '../../../store';
import {
    IS_ANONYMOUS_STORE_KEY,
    LOGOUT_DIALOG_STATE_STORE_KEY,
    MC_STORE_KEY,
    ROLES_STORE_KEY,
    TOKEN_DATA_STORE_KEY,
    TOKEN_STORE_KEY,
    TOKEN_WITH_ROLES_STORE_KEY,
} from '../../../services/utils/CONST';

const rootAddress: any = process.env.NEXT_PUBLIC_ROOT_ADDRESS;
const logoutUrl: any = process.env.NEXT_PUBLIC_LOGOUT_URL;

const LogoutDialog = () => {
    const [mc] = useStore<MC | null>(MC_STORE_KEY, null);
    const [logoutDialogState] = useStore<boolean>(LOGOUT_DIALOG_STATE_STORE_KEY, false);
    const [loadingState, setLoadingState] = useState(true);
    const [iframeUrl, setIframeUrl] = useState<any>(null);
    const [iframeState, setIframeState] = useState(true);
    const [, setToken] = useStore<string | null>(TOKEN_STORE_KEY, null);
    const [, setTokenWithRoles] = useStore<string | null>(TOKEN_WITH_ROLES_STORE_KEY, null);
    const [, setTokenData] = useStore<any>(TOKEN_DATA_STORE_KEY, null);
    const [, setRoles] = useStore<string[]>(ROLES_STORE_KEY, []);
    const [, setIsAnonymous] = useStore<boolean>(IS_ANONYMOUS_STORE_KEY, true);

    useEffect(() => {
        window.addEventListener('message', handleMessage, false);
    }, []);

    const handleMessage = (event: any) => {
        switch (event.data) {
            case 'success-logout': {
                handleLogout();
                return;
            }
            default: {
                return;
            }
        }
    };

    const handleLogout = () => {
        setLoadingState(true);
        setIframeState(false);
        setToken(null);
        setTokenWithRoles(null);
        setTokenData(null);
        setRoles([]);
        setIsAnonymous(true);
        if (mc) {
            const mcUrl = mc?.yourls?.mcUrl;
            if (mcUrl) window.location.replace(mcUrl);
        }
    };

    const setUrl = () => {
        const url = new URL(`${logoutUrl}`);
        url.searchParams.append('root-address', rootAddress);
        setIframeUrl(url.href);
    };

    useEffect(() => {
        setUrl();
    }, []);

    return (
        iframeUrl && (
            <Dialog open={logoutDialogState} fullScreen>
                {iframeState && (
                    <iframe
                        src={iframeUrl}
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            width: '100%',
                            height: '100%',
                            border: 'none',
                            margin: 0,
                            padding: 0,
                            overflow: 'hidden',
                            zIndex: 999999,
                        }}
                        onLoad={() => setLoadingState(false)}
                    >
                        {`Your browser doesn't support iframes`}
                    </iframe>
                )}

                <LoadingBackdrop open={loadingState} />
            </Dialog>
        )
    );
};

export default withLoginHandler()(LogoutDialog);
