import { Icon } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { AIR_TABLE_TOKEN_IDENTIFIER } from '../services/utils/CONST';
import { extractIdFromURI } from '../services/utils/ids';
import { Avatar } from '../services/utils/types';
import { parseCookies } from '../services/utils/cookie';
import { joinTokenFromCookies } from '../services/utils/token';
import qs from 'qs';

const FILE_SYSTEM_MAPPER_ENTRYPOINT = process.env.NEXT_PUBLIC_FILE_SYSTEM_MAPPER_ENTRYPOINT;

export const capitalizeString = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

const convertIconName = (iconName: string) => {
    let data = iconName.match(/[A-Z][a-z]+/g);
    let newData = '';
    data &&
        data.map((item) => {
            newData = `${newData}${item.toLowerCase()}_`;
        });
    return newData.slice(0, newData.length - 1);
};

export const extractIconAndName = (iconId: string) => {
    const temp = iconId.split('/');
    return temp[2].split(';');
};

export const resolveIcon = (iconId: string | null, defaultIcon: any, props?: any) => {
    const materialIconRegularExpression = /@material-ui\/icons\/.+/;
    if (iconId && materialIconRegularExpression.test(iconId)) {
        const dataName = extractIconAndName(iconId);
        const iconName = dataName[0];
        const color = dataName.length > 1 ? dataName[1] : null;
        return React.createElement(
            Icon,
            {
                ...(props ? props : {}),
            },
            convertIconName(iconName)
        );
    } else {
        return React.createElement(Icon, { ...(props ? props : {}) }, convertIconName(defaultIcon));
    }
};

export const renderDangerousText = (text: string, props: any = {}, el: string = 'span') => {
    const styles = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...(props.hasOwnProperty('style') ? props['style'] : {}),
    };
    const mStyles = props.hasOwnProperty('style') && props['style'] ? props['style'] : styles;
    return React.createElement(el, {
        ...props,
        style: {
            ...mStyles,
        },
        dangerouslySetInnerHTML: { __html: text },
    });
};

// export const toFormatDate = (stringDate: string, momentFormat = 'DD/MM/YYYY  hh:mm:ss') => {
export const toFormatDate = (stringDate: string, momentFormat = 'DD/MM/YYYY  HH:mm:ss') => {
    const date = moment(stringDate);
    return date.format(momentFormat);
};

export const getPathProcess = (startNode: any) => {
    let nodesTemp = [];
    let result = [];
    let visited: any = {};
    let resultVisited: any = {};

    if (
        (startNode.hasOwnProperty('isStartNodeBridgeToStartProcess') &&
            startNode.isStartNodeBridgeToStartProcess === true) ||
        (startNode.hasOwnProperty('name') && startNode.name.length === 0)
    ) {
        startNode =
            Array.isArray(startNode.nextNodes) && startNode.nextNodes.length > 0 ? startNode.nextNodes[0] : null;
    }

    if (startNode && startNode.hasOwnProperty('nextNodes')) {
        result.push(startNode);
        nodesTemp.push(startNode);
    }

    while (nodesTemp.length > 0) {
        let node: any = nodesTemp.shift();

        if (node.id in visited) {
            continue;
        }
        visited[node.id] = true;
        resultVisited[node.id] = true;

        let nextNodes = node.nextNodes && node.nextNodes.length > 0 ? node.nextNodes : null;

        if (nextNodes) {
            nextNodes.forEach((nextN: any) => {
                nodesTemp.push(nextN);
                if (!(nextN.id in resultVisited)) {
                    resultVisited[nextN.id] = true;
                    result.push(nextN);
                }
            });
        }
    }

    return result;
};

export const findNode = (nodeTypeToFind: string, firstNode: any) => {
    const nodes = getPathProcess(firstNode);

    let out: any = null;
    nodes.forEach((node) => {
        if (node.nodeTypes.includes(nodeTypeToFind)) out = node;
    });
    return out;
};

export const dataURLtoFile = (dataUrl: any, filename: any) => {
    let arr = dataUrl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};

export const getName = (nameWithPrefix: string) => {
    if (nameWithPrefix) return nameWithPrefix.split('-prefix-').reverse()[0];
    return nameWithPrefix;
};

export const getAirTableToken = (avatar: Avatar | null) => {
    if (!avatar) return '';

    // @ts-ignore
    const identifiers = avatar?.company?.identifiers;
    let token = '';
    if (identifiers) {
        identifiers.map((id: string) => {
            if (id.includes(AIR_TABLE_TOKEN_IDENTIFIER)) {
                token = id.split(':')[1];
            }
        });
    }
    return token;
};

export const generateRandomId = (): string => Math.random().toString(36).substr(2, 9);

export const hasRole = (rolesToCheck: string, roles: string[]) => roles.includes(rolesToCheck);

export const processSvg = (svgString: string) =>
    svgString.replace(/width="[0-9a-z]+"/, '').replace(/height="[0-9a-z]+"/, '');

export const getFileSystemMapperIdRequest = (
    tokenWithRoles: string,
    tagName: string,
    lastParam: { value: string; type: 'avatar' | 'groupMeta' | 'meta' },
    successCallback: (mediaId: string | null, fmId: string | null, fileName: string | null) => void,
    failureCallback: () => void
) => {
    let lastFilter = `parent.parent.name=sc:folder-`;

    switch (lastParam.type) {
        case 'groupMeta': {
            lastFilter = `${lastFilter}group_metadata_avatar_type:${lastParam.value}`;
            break;
        }
        case 'meta': {
            lastFilter = `${lastFilter}metadata_avatar_type:${lastParam.value}`;
            break;
        }
        default: {
            lastFilter = `${lastFilter}avatar:${lastParam.value}`;
            break;
        }
    }

    axios
        .get(
            `${FILE_SYSTEM_MAPPER_ENTRYPOINT}/smartConnectView/file_system_mappers?${qs.stringify({
                serialization_groups: ['read_tag'],
                lvl: 5,
                'tags.name': tagName,
                isFolder: false,
                'parent.name': 'system',
            })}&lastFilter`,
            {
                headers: {
                    Authorization: `Bearer ${tokenWithRoles}`,
                },
            }
        )
        .then(({ data }) => {
            if (data['hydra:member'].length > 0) {
                const media = data['hydra:member'][0];
                const name = media.name;
                const fmId = extractIdFromURI(media['@id']);
                const mediaId = media.metadata['metadata.extra']['coreapi.mediaid'];

                successCallback(mediaId, fmId, name);
            } else {
                successCallback(null, null, null);
            }
        })
        .catch(() => {
            failureCallback();
        });
};

export const getFileSystemMapperIdRequestAllData = (
    tokenWithRoles: string,
    tagName: string,
    lastParam: { value: string; type: 'avatar' | 'groupMeta' | 'meta' },
    successCallback: (data: any[]) => void,
    failureCallback: () => void
) => {
    let lastFilter = `parent.parent.name=sc:folder-`;

    switch (lastParam.type) {
        case 'groupMeta': {
            lastFilter = `${lastFilter}group_metadata_avatar_type:${lastParam.value}`;
            break;
        }
        case 'meta': {
            lastFilter = `${lastFilter}metadata_avatar_type:${lastParam.value}`;
            break;
        }
        default: {
            lastFilter = `${lastFilter}avatar:${lastParam.value}`;
            break;
        }
    }

    axios
        .get(
            `${FILE_SYSTEM_MAPPER_ENTRYPOINT}/smartConnectView/file_system_mappers?${qs.stringify({
                serialization_groups: ['read_tag'],
                lvl: 5,
                'tags.name': tagName,
                isFolder: false,
                'parent.name': 'system',
            })}&lastFilter`,
            {
                headers: {
                    Authorization: `Bearer ${tokenWithRoles}`,
                },
            }
        )
        .then(({ data }) => {
            if (data['hydra:member'].length > 0) {
                successCallback(data['hydra:member']);
            } else {
                successCallback([]);
            }
        })
        .catch(() => {
            failureCallback();
        });
};

export const getTicketingAccessCodeIdentifier = (avatar: Avatar) => {
    let code = '';
    const identifiers = 'identifiers' in avatar ? avatar.identifiers : null;
    if (identifiers) {
        identifiers.map((item: string) => {
            if (item.startsWith('ticketing_access_code:')) {
                code = item.split(':').reverse()[0];
            }
        });
    }
    return code;
};

export const getAxiosFn = (url: string, options?: { [key: string]: any }) => {
    const cookies = parseCookies();
    const token = joinTokenFromCookies(cookies);

    const withToken = options && 'withToken' in options ? options.withToken : true;

    return axios.get(url, {
        headers: {
            ...(withToken ? { Authorization: `Bearer ${token}` } : {}),
        },
    });
};

export const createAxiosFn = (url: string, data: any) => {
    const cookies = parseCookies();
    const token = joinTokenFromCookies(cookies);

    return axios.post(url, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const updateAxiosFn = (url: string, data: any) => {
    const cookies = parseCookies();
    const token = joinTokenFromCookies(cookies);

    return axios.put(url, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteAxiosFn = (url: string) => {
    const cookies = parseCookies();
    const token = joinTokenFromCookies(cookies);

    return axios.delete(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const calculatePages = (total: number, itemsPerPage: number) =>
    total < itemsPerPage ? 1 : Math.ceil(total / itemsPerPage);

export const getAvatarParent = (avatar: Avatar) =>
    'parent' in avatar && avatar.parent !== null ? avatar.parent : null;

export const getUrisFromObjects = (data: any[]) => data.map((item) => item['@id']);

export const composeDownloadFmUrl = (fmId: string, token?: any) =>
    `${FILE_SYSTEM_MAPPER_ENTRYPOINT}/smartConnectView/file_system_mappers/${fmId}/download${
        token ? `?bearer=${token}` : ''
    }`;
